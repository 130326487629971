<template>
  <div>
    <div
      class="flex justify-between  text-oWhite rounded-md w-48 cursor-pointer"
      :class="data.owner ? 'bg-green-500' : 'bg-gray-400'"
      @click="openModal()"
    >
      <div class="flex justify-center items-center px-3 py-1 ">
        <span v-if="data.owner">{{ data.owner.full_name }} </span>
        <span v-else>Assign Buyer</span>
      </div>
      <div class="flex justify-center items-center border-l w-7 p-0">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
    <t-modal
      :name="`buyer-assign-${id}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!isLoading"
      :clickToClose="!isLoading"
      ref="popup"
    >
      <div class="relative ">
        <loading :active="isBuyerListLoading" :is-full-page="false" />

        <section class="px-4 text-2xl font-extrabold text-center mt-8">
          {{
            $t(
              'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.modal.headline'
            )
          }}
        </section>
        <section class="px-4 py-5 mb-10 ">
          <TRichSelect
            class="h-96"
            v-model="form.owner"
            :options="buyerList"
            :close-on-select="true"
            :value-attribute="`id`"
            :text-attribute="`name`"
            :placeholder="
              $t(
                'components.marketPlaceManagement.orders.details.marketplace.steps.vehicles.modal.placeHolder'
              )
            "
          />
        </section>
        <section
          class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
        >
          <AppButton variant="secondary" :class="`w-1/2`" @click="closeModal()">
            Cancel
          </AppButton>

          <AppButton
            :class="`w-1/2`"
            :isLoading="isLoading"
            @click="assignBuyer()"
          >
            Assign
          </AppButton>
        </section>
      </div>
    </t-modal>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import xMan from '@/utils/xMan'
export default {
  name: 'VehicleAssignToBuyerAction',
  // components: {},
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isBuyerListLoading: true,
    isLoading: false,
    buyerList: [],
    form: {
      owner: '',
    },
  }),
  computed: {
    marketplaceId() {
      return this.$route.params.id
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: false,
      handler(val) {
        this.form.owner = val.owner.id
      },
    },
  },
  methods: {
    openModal() {
      this.fetchBuyerList()
      this.$modal.show(`buyer-assign-${this.id}`)
    },
    closeModal() {
      this.$modal.hide(`buyer-assign-${this.id}`)
    },
    async fetchBuyerList() {
      await this.$http
        .get(
          `${useEndpoints.dropdown.buyerList()}?order_id=${this.marketplaceId}`
        )
        .then((res) => {
          this.isBuyerListLoading = true
          const buyerListFromAPI = res.data.data.map((item) => {
            return {
              id: item.id,
              name: `${item.full_name} (${item.email})`,
            }
          })

          this.buyerList = [
            { id: 'none', name: 'Unassigned' },
            ...buyerListFromAPI,
          ]
        })
        .catch((err) => console.log('buyerlist-err', { err }))
        .finally(() => (this.isBuyerListLoading = false))
    },
    async assignBuyer() {
      this.isLoading = true
      if (this.form.owner === 'none') {
        const url = `${useEndpoints.vehicle.buyer.assignOwner(
          this.data.id
        )}?unassigned=true`

        await this.$http
          .patch(url)
          .then(() => {
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'Successful',
                text: 'Unassigned Successfully',
              },
              3000
            )
            this.closeModal()
            this.$emit('force-sync')
          })
          .catch((err) => {
            console.log('unassigned-err', { err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: 'Error occurred!',
                text: 'Failed to unassign',
              },
              7000
            )
          })
          .finally(() => (this.isLoading = false))
      } else {
        const url = useEndpoints.vehicle.buyer.assignOwner(this.data.id)
        const formData = new xMan().toFormData()
        formData.append('owner', this.form.owner)

        await this.$http
          .patch(url, formData)
          .then(() => {
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'Successful',
                text: 'Assigned Successfully',
              },
              3000
            )
            this.closeModal()
            this.$emit('force-sync')
          })
          .catch((err) => {
            console.log('assigned-err', { err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: 'Error occurred!',
                text: 'Failed to assign',
              },
              7000
            )
          })
          .finally(() => (this.isLoading = false))
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
